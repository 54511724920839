import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import logo from "../assets/css/images/serviceslogo.png"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="Services" />
    <div id="content">
      <ul>
        <li id="post-2" className="post-2 page type-page status-publish hentry">
          <div className="wrap_blog_text">
            <h2 className="services-headline">Services</h2>

            <div className="services-description">
              We are a full-service agency that has been helping small
              businesses use the web to grow their brands and companies since
              2010. The team at GetFresh Software has deep experience in all
              aspects of Digital Media including Software Development, Content
              Production, SEO, Social Media, Web Design, E-commerce and
              Infrastructure Management.
              <br /> <br /> If you need to strengthen your brand and have a
              unique identity to help you grow your business{" "}
              <AniLink fade to="/contact">
                get in touch with us
              </AniLink>{" "}
              to find out more about the services we provide.
            </div>

            <div className="services-wrapper">
              <div className="services-box online-branding">
                <h2 className="services-box-title">
                  <span>Online Branding</span>
                </h2>
                <div className="services-box-description">
                  <span />
                  As experts in story creation, we shape your brand identity to
                  convey a positive and compelling message to your audience. Our
                  branding program includes brand identity, messaging, web copy,
                  social media design, e-mail design, website design and
                  development. The cornerstone of any online marketing campaign
                  rests largely upon having the best branding possible.
                  <br />
                  <br />
                  We work with you to develop the brand that customers will
                  respond favorably to. So whether you are a professional
                  services company, e-commerce site or retail store and you need
                  to revitalize, enhance or develop a new brand image entirely,
                  you need the branding delivered by us. The supporting
                  materials and components you receive will drive you to
                  success, by promoting brand visibility, loyalty and driving
                  online sales.
                </div>
              </div>
              <div className="services-box web-design">
                <h2 className="services-box-title">
                  <span>Web Design</span>
                </h2>
                <div className="services-box-description">
                  <span />
                  With over 50% of all web traffic coming from a mobile device,
                  creating a mobile-friendly website is no longer optional: it’s
                  a necessity. It all starts with responsive web design.
                  <br />
                  <br />
                  Responsive web design allows your website to adapt to and
                  provide an optimal experience on any device it’s being viewed
                  from. This means that your website will look great and
                  function flawlessly for a user on a desktop computer, laptop,
                  tablet, or smartphone.
                  <br />
                  <br />
                  Every website developed by GetFresh Software utilizes
                  responsive web design, adapting perfectly to each device users
                  are accessing it from.
                </div>
              </div>

              <div className="services-box web-development">
                <h2 className="services-box-title">
                  <span>Web Development</span>
                </h2>
                <div className="services-box-description">
                  <span />
                  Web development is the process of creating a website or web
                  application. Our web designers will develop a logical site
                  structure, format the presentation of information, and
                  structure it all in an easy to manoeuvre and the aesthetically
                  pleasing site or application. <br /> <br />
                  Our front-end developers take this layout and transfer it to
                  code to ensure that your website or application displays
                  equally beautiful in all browsers, is convenient to use, and
                  works correctly on all devices. <br /> <br />
                  The following is the work of our back-end developers, which
                  consists of setting up the server part of the site or
                  application that is not visible to visitors. This includes
                  authorization, storage, and processing of data, email
                  distribution, and the like. The task of the tester is to carry
                  out quality control at all stages of development.
                </div>
              </div>

              <div className="services-box online-marketing">
                <h2 className="services-box-title">
                  <span>Online Marketing</span>
                </h2>
                <div className="services-box-description">
                  <span />
                  Marketing has changed more in the past 10 years than it had in
                  the previous 100—launching us out of the age of TV spots and
                  print ads and into the age of websites, online video, social
                  media, and Google. <br /> <br />
                  Consumers today are connected to the web 24/7. U.S. adults now
                  spend upwards of 6 hours per day consuming digital media, and
                  that doesn’t count those whose jobs are internet-dependent.
                  How do you turn these online consumers into paying customers?
                  With top-rated internet marketing services from an
                  award-winning online marketing company. Your website is
                  arguably your most important marketing asset and should serve
                  as your 24/7 online sales representative. But how easily can
                  it be found?
                </div>
              </div>
            </div>
            <p>
              <AniLink fade to="/contact/" className="services-button">
                contact us
              </AniLink>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </Layout>
)
